import * as React from 'react';
import { Button, Stack } from '@mui/material';
import { SelectedIcon } from '../ui/icons';

export default function ForUserStack(props) {
  const { users, purchase, onClick } = props;

  const userButtons = users.map((user) => {
    return (
      <Button
        sx={{
          fontSize: 14,
          padding: '5px 14px 6px 16px',
          marginRight: 1,
          '& .MuiButton-startIcon': {
            marginRight: '4px',
          },
        }}
        variant="outlined"
        color={purchase.for_user_id == user.id ? 'primary' : 'secondary'}
        key={user.id}
        value={user.id}
        onClick={onClick}
        startIcon={
          purchase.for_user_id == user.id ? (
            <SelectedIcon width="14" height="13" />
          ) : null
        }
      >
        {user.name}
      </Button>
    );
  });

  return (
    <Stack direction="row">
      <Button
        sx={{
          fontSize: 14,
          marginRight: 1,
          padding: '5px 14px 6px 16px',
          '& .MuiButton-startIcon': {
            marginRight: '4px',
          },
        }}
        variant="outlined"
        color={purchase.for_user_id == null ? 'primary' : 'secondary'}
        key={-1}
        value={-1}
        onClick={onClick}
        startIcon={
          purchase.for_user_id == null ? <SelectedIcon width="14" height="13" /> : null
        }
      >
        Shared
      </Button>
      {userButtons}
    </Stack>
  );
}
