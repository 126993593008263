import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@mui/material';
import { ChevronRightIcon, TrashIcon } from '../ui/icons';

/** Table that shows all expenses */
export default function ExpenseTable(props) {
  const { expenses, onDeleteExpense } = props;
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState(null);

  const handleOpenDeleteDialog = (expenseId) => {
    setSelectedID(expenseId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedID(null);
    setOpenDeleteDialog(false);
  };

  async function handleDeleteExpense(id) {
    try {
      const response = await axios.delete(`/api/expenses/${id}`);
      onDeleteExpense(id);
      setOpenDeleteDialog(false);
    } catch (e) {
      console.log(e);
    }
  }

  /** Table row that shows one expense (trip). Entire trip can be deleted  */
  const expenseRows = expenses.map((expense) => {
    return (
      <React.Fragment key={expense.id}>
        <span style={{ color: '#c4c4c4', fontSize: 12, fontWeight: 300 }}>
          {expense.date}
        </span>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          style={{
            marginTop: 8,
            marginBottom: 20,
            paddingLeft: 20,
            borderRadius: 6,
            background: 'rgba(255,255,255, .05)',
            color: '#F7C28E',
          }}
          p={1}
        >
          <Box>
            <Typography>{expense.merchant.name}</Typography>
          </Box>
          <Box>
            <Typography>${expense.total.toFixed(2)}</Typography>
          </Box>
          <Box>
            <IconButton
              onClick={() => handleOpenDeleteDialog(expense.id)}
            >
              <TrashIcon width="16" height="16" />
            </IconButton>
            <IconButton>
              <ChevronRightIcon width="14" height="14" />
            </IconButton>
          </Box>
        </Box>
      </React.Fragment>
    );
  });

  return (
    <>
      {expenseRows}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-expense"
      >
        <DialogTitle>{'Delete expense?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this expense?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDeleteExpense(selectedID);
            }}
          >
            Delete
          </Button>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
