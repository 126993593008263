import * as React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import {
  Box,
  IconButton,
  Chip,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
const dashboard = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none"
    viewBox="0 0 22 22"
  >
    <path
      stroke="#F7C28E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17.027 21H4.973C3.055 21 1.5 19.46 1.5 17.562V8.847a2.42 2.42 0 01.962-1.904l6.551-5.258a3.18 3.18 0 013.945 0l6.58 5.248a2.44 2.44 0 01.962 1.905v8.724C20.5 19.46 18.945 21 17.027 21z"
    ></path>
  </svg>
);

const expense = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="18"
    fill="none"
    viewBox="0 0 15 18"
  >
    <path
      fill="#F7C28E"
      fillRule="evenodd"
      d="M7.5 0a1 1 0 011 1v16a1 1 0 11-2 0V1a1 1 0 011-1zm6 6a1 1 0 011 1v10a1 1 0 11-2 0V7a1 1 0 011-1zm-12 4a1 1 0 011 1v6a1 1 0 11-2 0v-6a1 1 0 011-1z"
      clipRule="evenodd"
    ></path>
  </svg>
);

const payment = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="18"
    fill="none"
    viewBox="0 0 25 18"
  >
    <path
      fill="#F7C28E"
      fillRule="evenodd"
      d="M3.5 2a1 1 0 00-1 1v3h20V3a1 1 0 00-1-1h-18zm21 1a3 3 0 00-3-3h-18a3 3 0 00-3 3v12a3 3 0 003 3h18a3 3 0 003-3V3zm-2 5h-20v7a1 1 0 001 1h18a1 1 0 001-1V8z"
      clipRule="evenodd"
    ></path>
  </svg>
);

const active = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="5"
    height="4"
    fill="none"
    viewBox="0 0 5 4"
  >
    <path fill="#F7C28E" d="M4.5 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
  </svg>
);

export default function BottomNav() {
  const [value, setValue] = React.useState(0);

  return (
    <React.Fragment>
      {/* <Box display="flex" flexDirection="row" justifyContent="space-evenly" alignContent="center" p={2.5} sx={{ backgroundColor: '#000510' }}>
      <IconButton>
        {dashboard}
      </IconButton>
      <IconButton>
        {expense}
      </IconButton>
      <IconButton>
        {payment}
      </IconButton>
    </Box> */}
      <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={{ backgroundColor: '#000510' }}
        >
          <BottomNavigationAction icon={dashboard} component={Link} to="/" />
          <BottomNavigationAction
            icon={expense}
            component={Link}
            to="/create-expense"
          />
          <BottomNavigationAction icon={payment} component={Link} to="/" />
        </BottomNavigation>
      </Box>
    </React.Fragment>
  );
}
