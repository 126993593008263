import * as React from 'react';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { Box, SvgIcon, TextField, Typography } from '@mui/material';

function DateIcon(props) {
  return (
    <SvgIcon sx={{ width: '22px', height: '22px' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill="#F7C28E"
          fillRule="evenodd"
          d="M11 1.333c.184 0 .333.15.333.334v2.666a.333.333 0 01-.666 0V1.667c0-.184.149-.334.333-.334zM5 1.333c.184 0 .333.15.333.334v2.666a.333.333 0 11-.666 0V1.667c0-.184.149-.334.333-.334zM1.333 6.333c0-.184.15-.333.334-.333h12.666a.333.333 0 010 .667H1.667a.333.333 0 01-.334-.334z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#F7C28E"
          fillRule="evenodd"
          d="M2.333 3.333c-.147 0-.333.147-.333.42v9.834c0 .269.188.42.333.42h11.334c.145 0 .333-.151.333-.42V3.753c0-.269-.188-.42-.333-.42H2.333zm-1 .42c0-.566.414-1.086 1-1.086h11.334c.588 0 1 .529 1 1.086v9.834c0 .557-.412 1.086-1 1.086H2.333c-.588 0-1-.529-1-1.086V3.753z"
          clipRule="evenodd"
        ></path>
      </svg>
    </SvgIcon>
  );
}

export default function DatePicker(props) {
  const { value, onChange } = props;

  return (
    <React.Fragment>
      <Box my={2}>
        <Box mb={1}>
          <Typography variant="h2" component="h2">
            Expense date
          </Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Expense date"
            components={{ OpenPickerIcon: DateIcon }}
            fullWidth
            value={value}
            onChange={onChange}
            inputFormat="MMMM D, YYYY"
            disableMaskedInput={true}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                sx={{
                  button: { padding: '3px', marginRight: '-6px' },
                  svg: { paddingLeft: '4px', paddingTop: '3px', paddingBottom: '1px' },
                  fontSize: '14px',
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
    </React.Fragment>
  );
}
