import * as React from 'react';

import { AppBar, Box, Button, IconButton, Switch, Typography } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { ChevronLeftIcon, ExpenseSettingsIcon } from '../ui/icons';

export default function ExpenseAppbar(props) {
  const { onClickBack, onToggleTax, onToggleTip, onToggleFees, onToggleDiscount, getPageTitle } = props;

  const [open, setOpen] = React.useState(false);
  const [isTaxEnabled, setIsTaxEnabled] = React.useState(false);
  const [isTipEnabled, setIsTipEnabled] = React.useState(false);
  const [areFeesEnabled, setAreFeesEnabled] = React.useState(false);
  const [isDiscountEnabled, setIsDiscountEnabled] = React.useState(false);
 
  const handleClickBack = () => {
    onClickBack();
  };

  const handleToggleTax = (event) => {
    setIsTaxEnabled(event.target.checked);
    onToggleTax(event.target.checked);
  };

  const handleToggleTip = (event) => {
    setIsTipEnabled(event.target.checked);
    onToggleTip(event.target.checked);
  };

  const handleToggleFees = (event) => {
    setAreFeesEnabled(event.target.checked);
    onToggleFees(event.target.checked);
  };

  const handleToggleDiscount = (event) => {
    setIsDiscountEnabled(event.target.checked);
    onToggleDiscount(event.target.checked);
  };

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: '#191C24', backgroundImage: 'none', boxShadow: 'none',  }}
        enableColorOnDark
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ pt: 1.5, px: 2 }}>
          <IconButton onClick={handleClickBack}>
              <ChevronLeftIcon width="14" height="14" />
          </IconButton>
          <Typography component="h1" sx={{textTransform: 'uppercase', color: '#F7C28E', fontSize: '1.2rem' }}>
            {getPageTitle()}
          </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(true)}
            >
              <ExpenseSettingsIcon />
            </IconButton>
        </Box>
      </AppBar>

      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => {}}
      >
        <Box sx={{ width: 250, paddingHorizontal: 20 }} p={2}>
          <Box display="flex" justifyContent="space-betweeen" flexGrow={1} mb={1}>
            <Typography>Apply tax</Typography>
            <Switch size="small" checked={isTaxEnabled} onChange={handleToggleTax} />
          </Box>
          <Box display="flex" justifyContent="space-betweeen" flexGrow={1} mb={1}>
            <Typography>Apply tip</Typography>
            <Switch size="small" checked={isTipEnabled} onChange={handleToggleTip} />
          </Box>
          <Box display="flex" justifyContent="space-betweeen" flexGrow={1} mb={1}>
            <Typography>Apply trip discount</Typography>
            <Switch
              size="small"
              checked={isDiscountEnabled}
              onChange={handleToggleDiscount}
            />
          </Box>
          <Box display="flex" justifyContent="space-betweeen" flexGrow={1}>
            <Typography>Apply fees</Typography>
            <Switch size="small" checked={areFeesEnabled} onChange={handleToggleFees} />
          </Box>
        </Box>
      </SwipeableDrawer>
    </React.Fragment>
  );
}
