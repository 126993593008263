import * as React from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  CssBaseline,
  Typography,
} from '@mui/material';
import { useAuth } from '../use-auth.js';
import { Redirect } from 'react-router-dom';
import Spinner from '../components/ui/Spinner';

export default function Login() {
  let auth = useAuth();

  function handleSubmitLogin(event) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    auth.login(formData.get('email'), formData.get('password'));
  }

  return (
    <React.Fragment>
      {auth.isLoading ? (
        <Spinner />
      ) : auth.user ? (
        <Redirect to="/" />
      ) : (
        <Container maxWidth={'xs'}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component={'h1'} variant={'h5'}>
              Login
            </Typography>
            <Box component={'form'} onSubmit={handleSubmitLogin}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                fullWidth
                variant={'outlined'}
                type={'submit'}
                sx={{ mt: 3, mb: 2 }}
              >
                Login
              </Button>
              <Button
                fullWidth
                onClick={() => {
                  auth.logout();
                }}
                variant={'outlined'}
                type={'submit'}
                sx={{ mt: 3, mb: 2 }}
              >
                Logout
              </Button>
            </Box>
          </Box>
        </Container>
      )}
    </React.Fragment>
  );
}
