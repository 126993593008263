import * as React from 'react';
import { Box } from '@mui/material';

export default function TotalsTable(props) {
  const { totals } = props;

  return (
    <React.Fragment>
      {totals.map((total) => {
        return (
          <Box
            key={total.user_id}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            px={3}
            py={2}
            sx={{ background: 'rgba(0,0,0,.5)' }}
          >
            <Box>{total.user_name}</Box>
            <Box>${total.total.toFixed(2)}</Box>
          </Box>
        );
      })}
    </React.Fragment>
  );
}
