function ChevronDownIcon(props) {
  const { width, height } = props;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#F7C28E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 9l6 6 6-6"
      ></path>
    </svg>
  );

  return icon;
}

function ChevronLeftIcon(props) {
  const { width, height } = props;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 8 14"
    >
      <path
        stroke="#F7C28E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7 13L1 7l6-6"
      ></path>
    </svg>
  );

  return icon;
}

function ChevronRightIcon(props) {
  const { width, height, color } = props;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      color={color}
      fill="none"
      viewBox="0 0 8 14"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1 13l6-6-6-6"
      ></path>
    </svg>
  );

  return icon;
}

function CreateExpenseIcon(props) {
  // const { width, height } = props;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 24"
    >
      <path
        fill="#F7C28E"
        d="M16.5 6c0-3.308-2.692-6-6-6s-6 2.692-6 6H0v14.25A3.75 3.75 0 003.75 24h13.5A3.75 3.75 0 0021 20.25V6h-4.5zm-6-4.5C12.981 1.5 15 3.519 15 6H6c0-2.481 2.019-4.5 4.5-4.5zm9 18.75c0 1.24-1.01 2.25-2.25 2.25H3.75c-1.24 0-2.25-1.01-2.25-2.25V7.5h3v2.25a.75.75 0 001.5 0V7.5h9v2.25a.75.75 0 001.5 0V7.5h3v12.75z"
      ></path>
    </svg>
  );

  return icon;
}

function EditCategoriesIcon(props) {
  // const { width, height } = props;
      const icon = (
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      fill="none"
      viewBox="0 0 25 20"
    >
      <path
        fill="#F7C28E"
        d="M24.45 11.477l-7.973 7.974a1.875 1.875 0 01-2.651 0l-.07-.07.884-.883.07.069c.244.244.64.244.884 0l7.973-7.973a.626.626 0 000-.884L15.29 1.433a.62.62 0 00-.442-.183h-.763L12.835 0h2.013c.498 0 .974.198 1.326.55l8.277 8.276c.732.732.732 1.92 0 2.651zM9.849 1.25H1.875a.626.626 0 00-.625.625v7.973a.62.62 0 00.183.442l8.277 8.277c.244.244.64.244.884 0l7.973-7.973a.626.626 0 000-.884L10.29 1.433a.621.621 0 00-.442-.183zm0-1.25c.498 0 .975.198 1.326.55l8.277 8.276c.732.732.732 1.92 0 2.651l-7.974 7.974a1.875 1.875 0 01-2.651 0L.549 11.174A1.875 1.875 0 010 9.848V1.875C0 .839.84 0 1.875 0h7.973zM5.625 4.844a.782.782 0 000 1.562.782.782 0 000-1.562zm0-1.094a1.875 1.875 0 110 3.75 1.875 1.875 0 010-3.75z"
      ></path>
    </svg>
      );
  return icon;
}

function SelectedIcon(props) {
  const { width, height } = props;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 13 9"
    >
      <path
        fill="#F7C28E"
        fillRule="evenodd"
        d="M12.259.203c.27.27.27.708 0 .979L4.643 8.797a.692.692 0 01-.979 0L.203 5.336a.692.692 0 01.979-.98L4.154 7.33 11.28.203c.27-.27.708-.27.979 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  return icon;
}

function ExpenseSettingsIcon() {
  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <circle cx="15" cy="15" r="14.5" stroke="#F7C28E"></circle>
      <path
        fill="#F3BF8B"
        d="M21.563 18.25h-9.188v-.438a.47.47 0 00-.438-.437h-.874a.45.45 0 00-.438.438v.437H8.437a.45.45 0 00-.437.438v.875c0 .246.191.437.438.437h2.187v.438c0 .246.191.437.438.437h.874a.45.45 0 00.438-.438V20h9.188a.45.45 0 00.437-.438v-.875a.47.47 0 00-.438-.437zm0-4.375h-2.188v-.438a.47.47 0 00-.438-.437h-.875a.45.45 0 00-.437.438v.437H8.437a.45.45 0 00-.437.438v.874c0 .247.191.438.438.438h9.187v.438c0 .246.191.437.438.437h.875a.45.45 0 00.437-.438v-.437h2.188a.45.45 0 00.437-.438v-.874a.47.47 0 00-.438-.438zm0-4.375h-5.688v-.438a.47.47 0 00-.438-.437h-.874a.45.45 0 00-.438.438V9.5H8.437A.45.45 0 008 9.938v.874c0 .247.191.438.438.438h5.687v.438c0 .246.191.437.438.437h.874a.45.45 0 00.438-.438v-.437h5.688a.45.45 0 00.437-.438v-.874a.47.47 0 00-.438-.438z"
      ></path>
    </svg>
  );

  return icon;
}

function HeartBeatIcon(props) {
  const { width, height, color } = props;
  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      color={color}
      fill="none"
      viewBox="0 0 21 19"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20 9.5h-3.8L13.35 18 7.65 1 4.8 9.5H1"
      ></path>
    </svg>
  );

  return icon;
}

function RemoveIcon(props) {
  const { width, height } = props;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 15 12"
    >
      <path
        fill="#F7C28E"
        fillRule="evenodd"
        d="M4.517.411A.471.471 0 014.87.25h8.172a1.728 1.728 0 011.728 1.729V9.52a1.728 1.728 0 01-1.728 1.729H4.87a.471.471 0 01-.354-.161L.117 6.06a.471.471 0 010-.62L4.517.41zm.568.782L1.098 5.75l3.987 4.557h7.958a.785.785 0 00.786-.786V1.98a.786.786 0 00-.786-.786H5.085zM7.052 3.53a.471.471 0 01.667 0l1.552 1.552 1.553-1.552a.471.471 0 11.667.667L9.938 5.75l1.553 1.552a.471.471 0 01-.667.667L9.27 6.417 7.72 7.969a.471.471 0 11-.667-.667L8.605 5.75 7.052 4.198a.471.471 0 010-.667z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  return icon;
}


function RightArrowIcon(props) {
  const { width, height } = props;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 11 8"
    >
      <path
        fill="#647385"
        fillRule="evenodd"
        d="M5.832 7.875a.428.428 0 00.605 0l3.567-3.567a.428.428 0 000-.605L6.437.136a.428.428 0 10-.605.605L8.67 3.578H.428a.428.428 0 100 .856h8.24L5.833 7.27a.428.428 0 000 .605z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  return icon;
}

function TrashIcon(props) {
  const { width, height } = props;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 12 14"
    >
      <path
        fill="#FDC591"
        d="M4.927 5.244v5.197c0 .16-.128.289-.287.289h-.573a.288.288 0 01-.286-.289V5.244c0-.16.128-.289.286-.289h.573c.159 0 .287.13.287.289zm2.389-.289h-.574a.288.288 0 00-.286.289v5.197c0 .16.128.289.286.289h.574c.158 0 .286-.13.286-.289V5.244a.288.288 0 00-.286-.289zm3.153-2.31c.316 0 .573.259.573.577v.29c0 .159-.128.288-.287.288h-.478v8.085a1.15 1.15 0 01-1.146 1.155h-6.88a1.15 1.15 0 01-1.146-1.155V3.8H.628a.288.288 0 01-.287-.289v-.289c0-.318.257-.577.573-.577h1.778l.812-1.364A1.15 1.15 0 014.487.72h2.409a1.14 1.14 0 01.983.56l.812 1.365h1.777zm-6.44 0h3.325l-.417-.7a.144.144 0 00-.123-.07H4.57a.143.143 0 00-.123.07l-.417.7zM9.13 3.8h-6.88v7.94a.145.145 0 00.144.145h6.593a.143.143 0 00.143-.144V3.8z"
      ></path>
    </svg>
  );

  return icon;
}

function DiscountIcon(props) {
  const { width, height } = props;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 14 13"
    >
      <path
        fill="#F7C28E"
        fillRule="evenodd"
        d="M0 .614C0 .275.275 0 .614 0h6.142c.163 0 .319.065.434.18l5.277 5.277a1.842 1.842 0 010 2.598l-.001.001-4.403 4.404a1.845 1.845 0 01-2.607 0L.18 7.19A.614.614 0 010 6.756V.614zm1.228.614v5.273l5.096 5.09a.614.614 0 00.87 0l4.402-4.402a.614.614 0 000-.865v-.001L6.502 1.228H1.228zm1.843 2.457c0-.34.275-.614.614-.614h.006a.614.614 0 010 1.228h-.006a.614.614 0 01-.614-.614z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  return icon;
}

export { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, CreateExpenseIcon, DiscountIcon, EditCategoriesIcon, ExpenseSettingsIcon, HeartBeatIcon, SelectedIcon, RemoveIcon, RightArrowIcon, TrashIcon };
