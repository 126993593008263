import * as React from 'react';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import ForUserStack from '../ui/ForUserStack';
import { RemoveIcon } from '../ui/icons';
import '../../../css/global.css';

/** Component that shows tax, tip, fees, trip discount inputs */
export default function MiscInput(props) {
  const {
    name,
    purchaseId,
    users,
    onAdd,
    onRemove,
    onChange,
    enableAdd,
    enableRemove,
    addButtonText,
  } = props;

  const [purchase, setPurchase] = React.useState({
    item_name: name.toLowerCase(),
    for_user_id: null,
    quantity: 1,
    price_each: null,
    discount: null,
  });

  const handleChangeForUser = (event) => {
    console.info('You da sugar daddy');

    const forUserId = event.target.value == -1 ? null : Number(event.target.value);

    setPurchase((prevState) => {
      return { ...prevState, for_user_id: forUserId };
    });
  };

  const handleChangePriceEach = (event) => {
    setPurchase((prevState) => {
      return { ...prevState, price_each: Number(event.target.value) };
    });
  };

  React.useEffect(() => {
    onChange(purchaseId, purchase);
  }, [purchase]);

  return (
    <React.Fragment>
      <Box mt={4.5} mb={1}>
        <Typography variant="h2" component="h2">
          Misc. expense
        </Typography>
      </Box>
      <ForUserStack users={users} purchase={purchase} onClick={handleChangeForUser} />
      <Box mt={3} mb={1}>
        <Typography variant="h2" component="h2">
          {name}
        </Typography>
      </Box>
      {enableRemove && (
        <Button
          variant="text"
          startIcon={<RemoveIcon width="14" height="13" />}
          disableRipple
          sx={{
            fontSize: 11,
            padding: 0,
            margin: '0 -1px -6px 0',
            '& .MuiButton-startIcon': {
              marginRight: '4px',
            },
          }}
          onClick={() => onRemove(purchaseId)}
        >
          Remove
        </Button>
      )}
      <TextField
        fullWidth
        size="small"
        label=""
        placeholder="0"
        id="misc-input"
        onChange={handleChangePriceEach}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          'aria-label': 'Item discount',
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                marginRight: '2px',
                '& .MuiTypography-root': { fontSize: '14px' },
              }}
            >
              $
            </InputAdornment>
          ),
        }}
      />
      {enableAdd && (
        <Box mt={1}>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={onAdd}>
              {addButtonText}
            </Button>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
}
