import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Link,
  Snackbar,
  SwipeableDrawer,
} from '@mui/material';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/material/styles';
import ButtonSelector from '../components/ui/ButtonSelector';
import DatePicker from '../components/ui/DatePicker';
import DiscountInput from '../components/expense/DiscountInput';
import ExpenseCategoryAutocomplete from '../components/expense/ExpenseCategoryAutocomplete';
import ExpenseAppbar from '../components/expense/ExpenseAppbar';
import MerchantAutocomplete from '../components/expense/MerchantAutocomplete';
import PurchaseInputList from '../components/expense/PurchaseInputList';
import TotalsTable from '../components/expense/TotalsTable';
import Spinner from '../components/ui/Spinner';
import { useAuth } from '../use-auth.js';
import { usePrompt } from '../use-prompt.js';

import { ChevronLeftIcon, ChevronRightIcon } from '../components/ui/icons';

/** Create expense page contains all of the form elements */
export default function CreateExpense() {
  let auth = useAuth();
  let history = useHistory();

  const [isUserSelected, setIsUserSelected] = React.useState(false);
  const [isHouseholdLoaded, setIsHouseholdLoaded] = React.useState(false);
  const [areExpenseCategoriesLoaded, setAreExpenseCategoriesLoaded] =
    React.useState(false);
  const [areMerchantsLoaded, setAreMerchantsLoaded] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("I'm a snackbar");

  const [household, setHousehold] = React.useState(null);
  const [expenseCategories, setExpenseCategories] = React.useState([]);
  const [merchants, setMerchants] = React.useState([]);
  const [discount, setDiscount] = React.useState(0);

  const [reloadExpenseCategories, setReloadExpenseCategories] = React.useState(false);
  const [reloadMerchants, setReloadMerchants] = React.useState(true);
  const [purchaseInputListKey, setPurchaseInputListKey] = React.useState(
    new Date().getTime()
  );

  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedExpenseCategory, setSelectedExpenseCategory] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedMerchant, setSelectedMerchant] = React.useState(null);

  const [isTaxEnabled, setIsTaxEnabled] = React.useState(false);
  const [isTipEnabled, setIsTipEnabled] = React.useState(false);
  const [isDiscountEnabled, setIsDiscountEnabled] = React.useState(false);
  const [areFeesEnabled, setAreFeesEnabled] = React.useState(false);

  const [purchases, setPurchases] = React.useState([]);
  const [expenseSubtotals, setExpenseSubtotals] = React.useState([]);
  const [expenseTotal, setExpenseTotal] = React.useState(0);

  /** State indicating that the expense details page (e.g. merchant, date, etc) is
   *  active */
  const [expenseDetailsEnabled, setExpenseDetailsEnabled] 
  = React.useState(true);
  // = React.useState(false);

  /** State indicating that the expense line items (purchases) page (e.g. individual items, prices, totals, etc) is active */
  const [expenseLineItemsEnabled, 
    setExpenseLineItemsEnabled] = React.useState(false);
    // setExpenseLineItemsEnabled] = React.useState(true);

  usePrompt(isLoaded);

  const resetForm = () => {
    setSelectedExpenseCategory(null);
    setSelectedDate(null);
    setSelectedMerchant(null);

    setExpenseCategories([]);
    setReloadExpenseCategories(true);

    setPurchases([]);
    setPurchaseInputListKey(new Date().getTime());

    setExpenseSubtotals([]);
    setExpenseTotal(0);

    setIsTaxEnabled(false);
    setIsTipEnabled(false);
    setIsDiscountEnabled(false);
    setAreFeesEnabled(false);

    setExpenseLineItemsEnabled(false);
    setExpenseDetailsEnabled(true);
    // setExpenseLineItemsEnabled(true);
    // setExpenseDetailsEnabled(false);
  };

  const handleSnackbarClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackbarOpen(false);
  };

  const handleChangeSelectedUser = (userOrId) => {
    let user;
    if (isNaN(userOrId)) {
      user = userOrId;
    } else {
      user = household.users.find((user) => user.id == userOrId);
    }

    setSelectedUser(user);
    setIsUserSelected(true);
    setAreExpenseCategoriesLoaded(false);
    resetForm();
  };

  const showSnackbarMessage = (message) => {
    setSnackbarMessage(message);
    setIsSnackbarOpen(true);
  };

  async function handleSubmitExpense() {
    if (!selectedUser) {
      showSnackbarMessage("Current user invalid (this shouldn't happen)");
      return;
    }

    if (!selectedExpenseCategory) {
      showSnackbarMessage('No expense category selected');
      return;
    }

    if (!selectedDate) {
      showSnackbarMessage('No date selected');
      return;
    }

    if (!selectedMerchant) {
      showSnackbarMessage('No merchant selected');
      return;
    }

    if (purchases.length == 0) {
      showSnackbarMessage('No purchases entered');
      return;
    }

    const expense = {
      user_id: selectedUser.id,
      merchant_id: selectedMerchant.id,
      expense_category_id: selectedExpenseCategory.id,
      date: selectedDate.toISOString(),
      discount: discount,
      purchases: purchases,
    };

    setIsSubmitting(true);

    try {
      const response = await axios.post('/api/expenses', expense);
      resetForm();
      showSnackbarMessage('Expense submitted');
    } catch (e) {
      console.log(e);
      showSnackbarMessage('Error submitting expense');
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleChangeExpenseCategory = (expenseCategory) => {
    if (expenseCategory) {
      setSelectedExpenseCategory(expenseCategory);
    } else {
      setSelectedExpenseCategory(null);
    }
  };

  async function handleSubmitExpenseCategory(name, discount) {
    let newExpenseCategory = null;

    if (discount >= 1) {
      discount = discount / 100;
    }

    try {
      const response = await axios.post('/api/expense-categories', {
        user_id: selectedUser.id,
        name: name,
        discount: discount,
      });

      newExpenseCategory = response.data.expense_category;
      setReloadExpenseCategories(true);
    } catch (e) {
      console.log(e);
    }

    return newExpenseCategory;
  }

  const handleChangeDate = (date) => {
    setSelectedDate(date);
  };

  const handleChangeMerchant = (merchant) => {
    setSelectedMerchant(merchant);
  };

  async function handleSubmitMerchant(name) {
    let newMerchant = null;

    try {
      const response = await axios.post('/api/merchants', { name: name });

      newMerchant = response.data.merchant;
      setReloadMerchants(true);
    } catch (e) {
      console.log(e);
    }

    return newMerchant;
  }

  const handleChangePurchases = (purchases) => {
    setPurchases(purchases);
  };

  const handleClickBack = () => {
    if (expenseLineItemsEnabled) {
      setExpenseLineItemsEnabled(false);
      setExpenseDetailsEnabled(true);
    } else if (expenseDetailsEnabled) {
      history.push('/');
    }
  };

  const handleClickNext = () => {
    setExpenseDetailsEnabled(false);
    setExpenseLineItemsEnabled(true);
  };

  const handleToggleTax = (value) => {
    setIsTaxEnabled(value);
  };

  const handleToggleTip = (value) => {
    setIsTipEnabled(value);
  };

  const handleToggleDiscount = (value) => {
    setIsDiscountEnabled(value);
  };

  const handleToggleFees = (value) => {
    setAreFeesEnabled(value);
  };

  const handleChangeDiscount = (rawValue) => {
    const value = Number(rawValue);
    setDiscount(value == 0 ? null : value);
  };

  const getPageTitle = () => {
    if (expenseLineItemsEnabled && selectedMerchant) {
      return selectedMerchant.name;
    }
    // else if (miscInputsEnabled) {
    // return 'tax, tip, etc';
    // }
    // else if (reviewEnabled) {
    // return 'review';
    // }
    else return 'create expense';
  };

  function TotalsBox(props) {
    const { total, isClosed, onToggleDrawer, onSubmit } = props;

    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        px={3}
        py={2}
        sx={{
          background: '#161920',
          borderTop: '.5px solid rgba(255,255,255, .05)',
        }}
      >
        <Button onClick={onToggleDrawer} sx={{ padding: 0, fontSize: '18px' }}>
          Total ${total.toFixed(2)}{' '}
          <Box ml={1.5} className={isClosed ? 'rotate270' : 'rotate90'}>
            <ChevronLeftIcon width="6" height="12" />
          </Box>
        </Button>
        <Button onClick={onSubmit} variant="contained">
          Submit
        </Button>
      </Box>
    );
  }

  const [bottomNav, setBottomNav] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setBottomNav({ ...bottomNav, [anchor]: open });
  };

  const totalsPopup = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {isHouseholdLoaded && purchases && (
        <Grid item xs={12}>
          <TotalsTable totals={expenseSubtotals} />
        </Grid>
      )}
    </Box>
  );

  const indeterminate1Keyframes = keyframes({
    '0%': {
      left: '-35%',
      right: '100%',
    },
    '100%': {
      left: '0%',
      right: '0%',
    },
  });

  const StyledLinearProgress = styled(LinearProgress)({
    '& .MuiLinearProgress-bar1Indeterminate': {
      width: 'auto',
      animation: `${indeterminate1Keyframes} .01s linear forwards`,
    },
    '& .MuiLinearProgress-bar2Indeterminate': {
      display: 'none',
    },
  });

  function HomeIcon() {
    return (
      <svg width="13" height="9" fill="none" viewBox="0 0 13 9">
        <path
          fill="#F7C28E"
          fillRule="evenodd"
          d="M12.259.203c.27.27.27.708 0 .979L4.643 8.797a.692.692 0 01-.979 0L.203 5.336a.692.692 0 01.979-.98L4.154 7.33 11.28.203c.27-.27.708-.27.979 0z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  }

  React.useEffect(() => {
    async function loadHousehold() {
      if (auth.user == null) {
        setHousehold(null);
        return;
      }

      const response = await axios.get('/api/households/mine');
      setHousehold(response.data.household);
      setIsHouseholdLoaded(true);
    }

    loadHousehold();
  }, [auth.user]);

  React.useEffect(() => {
    async function selectCurrentUser() {
      if (auth.user == null) {
        setSelectedUser(null);
        setIsUserSelected(false);
        return;
      }

      handleChangeSelectedUser(auth.user);
    }

    selectCurrentUser();
  }, [household]);

  React.useEffect(() => {
    async function loadExpenseCategories() {
      setReloadExpenseCategories(false);

      if (selectedUser == null) {
        setExpenseCategories(null);
        return;
      }

      const response = await axios.get(
        `/api/users/${selectedUser.id}/expense-categories`
      );

      setExpenseCategories(response.data.expense_categories);
      //setSelectedExpenseCategory(response.data.expense_categories[0]);
      setAreExpenseCategoriesLoaded(true);
    }

    loadExpenseCategories();
  }, [selectedUser, reloadExpenseCategories]);

  React.useEffect(() => {
    async function loadMerchants() {
      setReloadMerchants(false);

      const response = await axios.get('/api/merchants');
      setMerchants(response.data.merchants);
      //setSelectedMerchant(response.data.merchants[0]);
      setAreMerchantsLoaded(true);
    }

    loadMerchants();
  }, [reloadMerchants]);

  React.useEffect(() => {
    async function calculateSubtotals() {
      if (household == null) {
        return;
      }

      let subtotalsMap = new Map();

      subtotalsMap.set(null, 0);
      for (const user of household.users) {
        subtotalsMap.set(user.id, 0);
      }

      subtotalsMap = purchases.reduce((subtotals, purchase) => {
        if (typeof purchase.item_name == 'undefined') {
          return subtotals;
        }

        const cost = purchase.price_each * purchase.quantity - purchase.discount;
        subtotals.set(
          purchase.for_user_id,
          (subtotals.get(purchase.for_user_id) ?? 0) + cost
        );
        return subtotals;
      }, subtotalsMap);

      let newSubtotals = [];
      for (const [userId, total] of subtotalsMap) {
        const user = household.users.find((user) => user.id == userId) ?? null;
        const name = user == null ? 'Shared' : user.name;

        if (user == null) {
          total -= discount;
        }

        newSubtotals.push({
          user_id: userId,
          user_name: name,
          total: total,
        });
      }

      setExpenseSubtotals(newSubtotals);
    }

    calculateSubtotals();
  }, [purchases, discount]);

  React.useEffect(() => {
    async function calculateExpenseTotal() {
      if (household == null) {
        return;
      }

      let newTotal = expenseSubtotals.reduce((total, subtotal) => {
        total += subtotal.total;

        return total;
      }, 0);

      setExpenseTotal(newTotal);
    }

    calculateExpenseTotal();
  }, [expenseSubtotals]);

  React.useEffect(() => {
    async function updateIsLoaded() {
      setIsLoaded(
        auth.user &&
          isUserSelected &&
          isHouseholdLoaded &&
          setAreExpenseCategoriesLoaded &&
          areMerchantsLoaded
      );
    }

    updateIsLoaded();
  }, [
    auth.user,
    isUserSelected,
    isHouseholdLoaded,
    areExpenseCategoriesLoaded,
    areMerchantsLoaded,
  ]);

  const expenseDetails = (
    <Box pt={9} px={3} sx={{ display: (expenseDetailsEnabled && 'inherit') || 'none' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {isHouseholdLoaded && isUserSelected && (
          <Grid item xs={12}>
            <Box pb={2.5}>
              <ButtonSelector
                title={household.name}
                options={household.users}
                selectedId={selectedUser.id}
                onChange={handleChangeSelectedUser}
              />
            </Box>
          </Grid>
        )}
        {(areExpenseCategoriesLoaded && areMerchantsLoaded && (
          <React.Fragment>
            <Grid item xs={12}>
              <Box pb={1}>
                <DatePicker
                  value={selectedDate}
                  onChange={handleChangeDate}
                  InputAdornmentProps={{}}
                  components={{
                    OpenPickerIcon: HomeIcon,
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box pb={2.5}>
                <ExpenseCategoryAutocomplete
                  options={expenseCategories}
                  value={selectedExpenseCategory}
                  onChange={handleChangeExpenseCategory}
                  onSubmit={handleSubmitExpenseCategory}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <MerchantAutocomplete
                options={merchants}
                value={selectedMerchant}
                onChange={handleChangeMerchant}
                onSubmit={handleSubmitMerchant}
              />
            </Grid>
          </React.Fragment>
        )) || <Spinner />}
      </Grid>

      {selectedUser && selectedExpenseCategory && selectedDate && selectedMerchant && (
        <Link
          color="primary"
          onClick={handleClickNext}
          sx={{ textDecoration: 'none', textTransform: 'uppercase' }}
        >
          <Box
            display="flex"
            flexDirection="row"
            sx={{
              position: 'fixed',
              bottom: 0,
              right: 24,
              paddingBottom: '20px',
              fontSize: 16,
              cursor: 'pointer',
            }}
          >
            <Box>Next</Box>
            <Box pl={0.5}>
              <ChevronRightIcon width="10" height="10" />
            </Box>
          </Box>
        </Link>
      )}
    </Box>
  );

  const expenseLineItems = (
    <Box pt={5} px={3} sx={{ display: expenseLineItemsEnabled ? 'inherit' : 'none' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {isHouseholdLoaded && (
          <Grid item xs={12}>
            <PurchaseInputList
              key={purchaseInputListKey}
              users={household.users}
              onChange={handleChangePurchases}
              enableTax={isTaxEnabled}
              enableTip={isTipEnabled}
              enableDiscount={isDiscountEnabled}
              enableFees={areFeesEnabled}
            />
            {isHouseholdLoaded && isDiscountEnabled && (
              <DiscountInput onChange={handleChangeDiscount} />
            )}
          </Grid>
        )}
      </Grid>
      {/* Drawer starts */}
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
        }}
      >
        {['bottom'].map((anchor) => (
          <React.Fragment key={anchor}>
            <TotalsBox
              total={expenseTotal}
              isClosed={true}
              onToggleDrawer={toggleDrawer(anchor, true)}
              onSubmit={handleSubmitExpense}
            />
            <SwipeableDrawer
              anchor={anchor}
              open={bottomNav[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              <TotalsBox
                total={expenseTotal}
                isClosed={false}
                onToggleDrawer={toggleDrawer(anchor, false)}
                onSubmit={handleSubmitExpense}
              />
              {totalsPopup(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </div>
    </Box>
  );

  if (!isLoaded) {
    return <StyledLinearProgress />;
  } else {
    return (
      <React.Fragment>
        {isSubmitting && <Spinner />}
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
        <ExpenseAppbar
          onClickBack={handleClickBack}
          onToggleTax={handleToggleTax}
          onToggleTip={handleToggleTip}
          onToggleDiscount={handleToggleDiscount}
          onToggleFees={handleToggleFees}
          getPageTitle={getPageTitle}
        />
        {expenseDetails}
        {expenseLineItems}
      </React.Fragment>
    );
  }
}
