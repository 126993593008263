import * as React from 'react';
import { Box } from '@mui/material';
import PurchaseInput from './PurchaseInput';
import MiscInputList from './MiscInputList';

export default function PurchaseInputList(props) {
  const { users, onChange, enableTax, enableTip, enableDiscount, enableFees } = props;

  const [purchases, setPurchases] = React.useState([{ id: 0 }]);
  const [nextPurchaseId, setNextPurchaseId] = React.useState(1);

  const [miscPurchases, setMiscPurchases] = React.useState([]);

  const handleChangePurchase = (updatedPurchaseId, updatedPurchase) => {
    setPurchases(
      purchases.map((purchase) => {
        if (purchase.id == updatedPurchaseId) {
          return { ...updatedPurchase, id: purchase.id };
        }

        return purchase;
      })
    );
  };

  const handleAddPurchase = () => {
    setPurchases([...purchases, { id: nextPurchaseId }]);
    setNextPurchaseId(nextPurchaseId + 1);
  };

  const handleRemovePurchase = (purchaseId) => {
    setPurchases(purchases.filter((purchase) => purchase.id != purchaseId));
  };

  const handleChangeMiscPurchases = (updatedMiscPurchases) => {
    setMiscPurchases(updatedMiscPurchases);
  };

  React.useEffect(() => {
    var combinedPurchases = [...purchases];

    if (enableTax || enableTip || enableDiscount || enableFees) {
      combinedPurchases.push(...miscPurchases);
    }

    onChange(combinedPurchases);
  }, [purchases, miscPurchases]);

  return (
    <Box>
      {purchases.map((purchase) => (
        <PurchaseInput
          purchaseId={purchase.id}
          users={users}
          onAdd={handleAddPurchase}
          onRemove={handleRemovePurchase}
          onChange={handleChangePurchase}
          enableAdd={purchase.id == purchases.slice(-1)[0].id}
          enableRemove={purchases.length > 1}
          key={purchase.id}
        />
      ))}
      <MiscInputList
        users={users}
        onChange={handleChangeMiscPurchases}
        enableTax={enableTax}
        enableTip={enableTip}
        enableDiscount={enableDiscount}
        enableFees={enableFees}
      />
    </Box>
  );
}
