import * as React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Box, Button, Grid, Menu, MenuItem, Paper, Snackbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import BottomNav from '../components/ui/BottomNav';
import ConfirmDialog from '../components/ui/ConfirmDialog';
import Spinner from '../components/ui/Spinner';
import { CreateExpenseIcon, EditCategoriesIcon, HeartBeatIcon, RightArrowIcon } from '../components/ui/icons';
import { useAuth } from '../use-auth.js';

import '../../css/global.css';
import avatar from '../../assets/img/jane.png';
// import paymentBG from '../../assets/img/bg-payment.jpg';

const paymentBackground = {
  height: 164,
  //  backgroundImage: 'url(${"../static/DSC_1037.jpg"})',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: 'auto',
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(),
  textAlign: 'center',
  background: '#13171F',
  borderRadius: '10px',
  paddingX: 40,
  paddingTop: 30,
  paddingBottom: 20,
  alignItems: 'center',
  alignSelf: 'center',
  color: '#647385',
  textTransform: 'uppercase',
}));

export default function Home() {
  let auth = useAuth();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("I'm a snackbar");

  const [household, setHousehold] = React.useState(null);

  const [usersOwed, setUsersOwed] = React.useState([]);
  const [usersIndebted, setUsersIndebted] = React.useState([]);
  const [isAllSettled, setIsAllSettled] = React.useState(true);

  const [refreshPaymentsDue, setRefreshPaymentsDue] = React.useState(false);

  const [payment, setPayment] = React.useState(null);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const showSnackbarMessage = (message) => {
    setSnackbarMessage(message);
    setIsSnackbarOpen(true);
  };

  const getUserName = (userId) => {
    for (const user of household.users) {
      if (user.id == userId) {
        return user.name;
      }
    }

    return null;
  };

  const handleSnackbarClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackbarOpen(false);
  };

  const handleClickPayment = (toUserId, amount) => {
    setPayment({ to_user_id: toUserId, amount: amount });
    setIsPaymentDialogOpen(true);
  };

  const handleCancelPayment = (_event) => {
    setPayment(null);
    setIsPaymentDialogOpen(false);
  };

  const handleClickSettings = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSettings = () => {
    setAnchorEl(null);
  };

  async function handleConfirmPayment(_event) {
    setIsPaymentDialogOpen(false);
    setIsSubmitting(true);

    try {
      const response = await axios.post('/api/payments', payment);
      setRefreshPaymentsDue(true);
      showSnackbarMessage('Payment submitted');
    } catch (e) {
      showSnackbarMessage('Error submitting payment');
    } finally {
      setIsSubmitting(false);
    }

    setPayment(null);
  }

  React.useEffect(() => {
    async function loadHousehold() {
      if (auth.user == null) {
        setHousehold(null);
        return;
      }

      const response = await axios.get(`/api/households/mine`);
      setHousehold(response.data.household);
    }

    loadHousehold();
  }, [auth.user]);

  React.useEffect(() => {
    async function loadPaymentsDue() {
      if (household == null) {
        setUsersOwed([]);
        setUsersIndebted([]);
        setIsAllSettled(true);
        return;
      }

      const response = await axios.get('/api/payments-due');

      let newIsAllSettled = true;
      let newUsersOwed = [];
      let newUsersIndebted = [];

      for (const debt of response.data.payments_due) {
        if (debt.from_user_id === auth.user.id && Math.abs(debt.total) >= 0.01) {
          newIsAllSettled = false;
          newUsersOwed.push({
            to_user_id: debt.to_user_id,
            to_user_name: getUserName(debt.to_user_id),
            amount: debt.total,
          });
        } else if (debt.to_user_id === auth.user.id && Math.abs(debt.total) >= 0.01) {
          newIsAllSettled = false;
          newUsersIndebted.push({
            from_user_id: debt.from_user_id,
            from_user_name: getUserName(debt.from_user_id),
            amount: debt.total,
          });
        }
      }

      setUsersOwed(newUsersOwed);
      setUsersIndebted(newUsersIndebted);
      setIsAllSettled(newIsAllSettled);
      setRefreshPaymentsDue(false);
    }

    loadPaymentsDue();
  }, [household, refreshPaymentsDue]);

  const owedBoxes = usersOwed.map((debt) => {
    return (
      <div className="debt-item" key={`${debt.from_user_id}_${debt.to_user_id}`}>
        <Box display="flex" flexDirection="column">
          <Box className="debts" key={debt.to_user_id}>
            <Box sx={{ width: '47%', paddingTop: '34px', whiteSpace: 'normal' }}>
              <span className="debtor" style={{ marginBottom: '-6px', color: 'gray' }}>
                WINNER
              </span>
              <span className="debtor">WINNER</span>

              <p className="debt-message">
                You owe {debt.to_user_name} a sushi dinner!
              </p>
            </Box>
          </Box>

          {/* Balanced owed / pay */}
          <div className="debt-cta">
            <div className="balance">
              <span>Balance owed</span> <strong>${debt.amount.toFixed(2)}</strong>
            </div>
            <div className="pay">
              <Button
                onClick={() => handleClickPayment(debt.to_user_id, debt.amount)}
                sx={{ padding: 0, lineHeight: 'auto', minWidth: 'auto' }}
              >
                Pay
              </Button>
            </div>
          </div>
        </Box>
      </div>
    );
  });

  const indebtedBoxes = usersIndebted.map((debt) => {
    return (
      <div className="debt-item" key={`${debt.from_user_id}_${debt.to_user_id}`}>
        <Box display="flex" flexDirection="column">
          <Box className="indebt" key={debt.from_user_id}>
            <Box py={5} sx={{ paddingTop: '20px', whiteSpace: 'normal' }}>
              <p className="indebtor">{debt.from_user_name} owes you money</p>
              <p className="debt-message">Need it yesterday? Call Paulie.</p>
            </Box>
          </Box>

          {/* Balanced credit */}
          <div className="indebt-cta">
            <div className="balance">
              <span>F*ck you, pay me</span> <strong>${debt.amount.toFixed(2)}</strong>
            </div>
          </div>
        </Box>
      </div>
    );
  });

  const squaredBox = (
    <Box>
      <Box display="flex" flexDirection="column">
          <Box className="indebt">
            <Box py={5} sx={{ paddingTop: '20px', whiteSpace: 'normal' }}>
              <p className="indebtor">Debt's paid</p>
              <p className="debt-message">Now we're square</p>
            </Box>
          </Box>
          <div className="indebt-cta">
            <div className="balance">
             
            </div>
          </div>
        </Box>
    </Box>
  );

  return (
    <>
    <Box mx={3}>
      {isSubmitting && <Spinner />}
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        py={4}
        // px={3}
      >
        <Box display="flex"
        flexDirection="row" alignItems="center">
        <Avatar alt="bk" src={avatar} sx={{ width: 60, height: 60 }} />
        <Box pl={2}>
          <Typography
            variant="body1"
            component="p"
            color="primary"
            sx={{ fontSize: 20, fontWeight: 300 }}
          >
            {auth.user && auth.user.name}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            color="#C4C4C4"
            sx={{ fontSize: 12, fontWeight: 300, textTransform: 'uppercase' }}
          >
            {household && household.name}
          </Typography>
        </Box>
        </Box>
        <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickSettings}
      >
        <CreateExpenseIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseSettings}
        sx={{backgroundColor: 'rgba(0,0,0,.2)'}}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem disabled onClick={handleCloseSettings}>Edit reward categories</MenuItem>
        <MenuItem disabled onClick={handleCloseSettings}>Change password</MenuItem>
        <MenuItem disabled onClick={handleCloseSettings}>Logout</MenuItem>
      </Menu>
      </Box>

      {household && isAllSettled && squaredBox}

      <div className="payment-scroller sticky-top">
        <Box
          className="nav"
          justifyContent="space-between"
          display="flex"
          flexDirection="row"
        >
          {household && owedBoxes}
          {household && indebtedBoxes}
        </Box>
      </div>

      <ConfirmDialog
        title="Submit payment"
        open={isPaymentDialogOpen}
        onClose={handleCancelPayment}
        onConfirm={handleConfirmPayment}
      />

      <Box mt={1} pb={.75} display="flex" flexDirection="row" alignItems="center">
        <Typography color="#647385">Your snapshot</Typography>
        <Box sx={{paddingTop: '.25px', paddingLeft: '8px'}}>
          <Link to="/expense-history">
            <RightArrowIcon width="11" height="8" />
          </Link>
        </Box>
      </Box>
      
      <Box sx={{backgroundColor: "#13171F", borderRadius: '10px'}} p={3} mb={3} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography color="primary">$420.29</Typography>
            <Typography sx={{color: '#647385', textTransform: 'uppercase', fontSize: 12}}>This month</Typography>
          </Box>
          <Box>
            <HeartBeatIcon width="20" height="20" color="#F7C28E" />
          </Box>
          <Box sx={{textAlign: 'right'}}>
            <Typography color="primary">$1000.33</Typography>
            <Typography sx={{color: '#647385', textTransform: 'uppercase', fontSize: 12}}>Last month</Typography>
          </Box>
      </Box>

      <Box
        sx={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'space-around',
          flex: 1,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3} rowspacing={3}>
            <Grid item xs={6} md={6}>
              <Link to="/create-expense">
                <Item>
                  <CreateExpenseIcon />
                  <p className="dashboard-item">Create expense</p>
                </Item>
              </Link>
            </Grid>
            {/* <Grid item xs={6} md={6}>

              <Item>
                <EditCategoriesIcon />
                <p className="dashboard-item">Edit categories</p>
              </Item>
    
            </Grid> */}
            <Grid item xs={6} md={6}>
              <Link to="/expense-history">
                <Item>
                <EditCategoriesIcon />
                <p className="dashboard-item">Expense History</p>
                </Item>
              </Link>
            </Grid>
            {/* <Grid item xs={6} md={6}>
              <Item>Settings</Item>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
      
    </Box>
    <BottomNav />
    </>
  );
}
