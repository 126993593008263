import * as React from 'react';
import { useEffect, useState} from 'react';
import { Button, Box } from '@mui/material';

function ScrollTop () {
    const [scrollButton, setScrollButton] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
          if (window.scrollY > 400) {
            setScrollButton(true);
          } else {
            setScrollButton(false);
          }
        });
    })

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }
    return (
        <Box>
            {scrollButton && (
                <Button sx={{position: 'fixed', bottom: 84, right: 24, alignItems: 'center', height: 50,  fontSize: 12, background: 'black', color: 'white' }} onClick={scrollUp}>Scroll to top</Button>   
            )}
        </Box>
    );
}

export default ScrollTop;