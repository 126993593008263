import * as React from 'react';
import { Box, TextField, Typography } from '@mui/material';

import '../../../css/global.css';

export default function PurchaseInput(props) {
  const { onChange } = props;

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <React.Fragment>
      <Box mt={4.5} mb={1.5}>
        <Typography variant="h2" component="h2">
          Trip discount
        </Typography>
      </Box>
      <Box mb={2}>
        <TextField
        fullWidth
        label="Discount"
        size="small"
        placeholder="0"
        id="discount"
        onChange={handleChange}
        />
      </Box>
    </React.Fragment>
  );
}
