import * as React from 'react';
import { useLocation } from 'react-router-dom';

/** Page where you can see all the items from one expense */
export default function ViewExpense() {
  const state = useLocation();
  const { expenseId } = state;

  return <p>I should be looking up expense {expenseId} right now</p>;
}
