import * as React from 'react';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import { RemoveIcon, DiscountIcon } from '../ui/icons';
import ForUserStack from '../ui/ForUserStack';
import ItemAutocomplete from './ItemAutocomplete';

import '../../../css/global.css';

/** Component where you input who the expense is for and all the details for the item (name, cost, qty, discount) */
export default function PurchaseInput(props) {
  const { purchaseId, users, onAdd, onRemove, onChange, enableAdd, enableRemove } =
    props;

  const [items, setItems] = React.useState([]);
  const [purchase, setPurchase] = React.useState({
    item_name: null,
    for_user_id: null,
    quantity: 1,
    price_each: null,
    discount: null,
  });

  const handleChangeForUser = (event) => {
    console.info('You da sugar daddy');

    const forUserId = event.target.value == -1 ? null : Number(event.target.value);

    setPurchase((prevState) => {
      return { ...prevState, for_user_id: forUserId };
    });
  };

  async function narrowItems(query) {
    if (!query || query.length < 3) {
      setItems([]);
      return;
    }

    try {
      const response = await axios.get(`/api/items?q=${query}`);
      setItems(response.data.items);
    } catch (e) {
      console.log(e);
      setItems([]);
    }
  }

  const handleChangeQuery = (query) => {
    narrowItems(query);
  };

  const handleChangeName = (name) => {
    setPurchase((prevState) => {
      return { ...prevState, item_name: name };
    });
  };

  const handleChangePriceEach = (event) => {
    setPurchase((prevState) => {
      return { ...prevState, price_each: Number(event.target.value) };
    });
  };

  const handleChangeQuantity = (event) => {
    setPurchase((prevState) => {
      return { ...prevState, quantity: Number(event.target.value) };
    });
  };

  const handleChangeDiscount = (event) => {
    setPurchase((prevState) => {
      return { ...prevState, discount: Number(event.target.value) };
    });
  };

  React.useEffect(() => {
    onChange(purchaseId, purchase);
  }, [purchase]);

  return (
    <React.Fragment>
      <Box mt={4.5} mb={1}>
        <Typography variant="h2" component="h2">
          Expense
        </Typography>
      </Box>
      <ForUserStack users={users} purchase={purchase} onClick={handleChangeForUser} />
      <Box pt={2.5}>
        <Grid container spacing={2}>
          <Grid item lg={12} xs={12}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box my={1}>
                <Typography variant="h2" component="h2">
                  Item details
                </Typography>
              </Box>

              {enableRemove && (
                <Button
                  variant="text"
                  startIcon={<RemoveIcon width="14" height="13" />}
                  disableRipple
                  sx={{
                    fontSize: 11,
                    padding: 0,
                    margin: '0 -1px -6px 0',
                    '& .MuiButton-startIcon': {
                      marginRight: '4px',
                    },
                  }}
                  onClick={() => onRemove(purchaseId)}
                >
                  Remove
                </Button>
              )}
            </Box>
            <ItemAutocomplete
              options={items}
              onChange={handleChangeName}
              onInputChange={handleChangeQuery}
            />
          </Grid>
          <Grid item lg={5} xs={5}>
            <TextField
              fullWidth
              label="Item cost"
              autoComplete="new-password"
              size="small"
              placeholder="0"
              onChange={handleChangePriceEach}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                'aria-label': 'Item cost',
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      marginRight: '-10px',
                      '& .MuiTypography-root': { fontSize: '14px' },
                    }}
                  >
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item lg={2} xs={3}>
            <TextField
              id="item-quantity"
              label="Item quantity"
              fullWidth
              select
              value={purchase.quantity}
              onChange={handleChangeQuantity}
              size="small"
            >
              {quantities.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    '& .MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected': {
                      backgroundColor: 'red',
                    },
                    '& .MuiButtonBase-root-MuiMenuItem-root.Mui-selected': {
                      backgroundColor: 'red',
                    },
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={5} xs={4}>
            <TextField
              fullWidth
              size="small"
              label="Discount"
              placeholder="Discount"
              autoComplete="new-password"
              onChange={handleChangeDiscount}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                'aria-label': 'Item discount',
                startAdornment: (
                  <InputAdornment position="start" 
                  sx={{
                    marginRight: '-10px',
                    '& .MuiTypography-root': { fontSize: '14px' },
                  }}>
                    <DiscountIcon width="14" height="13" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {enableAdd && (
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={onAdd}>
                Add item
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
}

const quantities = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
];
