import * as React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

export default function ItemAutocomplete(props) {
  const { options, onChange, onInputChange } = props;
  const [value, setValue] = React.useState(null);

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onInputChange={(_event, newValue) => {
          setValue(newValue);
          onInputChange(newValue);
          onChange(newValue);
        }}
        selectOnFocus
        handleHomeEndKeys
        id="item-autocomplete"
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Regular option
          return option.name;
        }}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        fullWidth
        size="small"
        freeSolo
        renderInput={(params) => <TextField {...params} label="Item details" />}
      />
    </React.Fragment>
  );
}
