import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Toolbar,
  Typography,
} from '@mui/material';
import { ChevronLeftIcon } from '../components/ui/icons';
import BottomNav from '../components/ui/BottomNav';
import Spinner from '../components/ui/Spinner';
import ExpenseTable from '../components/expense/ExpenseTable';
import ScrollTop from '../components/expense/ScrollTop';

/** Expense history page shows current month's transactions and can be filtered by month/year */
export default function ExpenseHistory() {
  let history = useHistory();
  const theme = useTheme();
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("I'm a snackbar");

  const [areExpensesLoaded, setAreExpensesLoaded] = React.useState(false);
  const [expenses, setExpenses] = React.useState([]);
  const [month, setMonth] = React.useState('March');
  const [year, setYear] = React.useState('2022');

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const handleClickBack = () => {
    if (areExpensesLoaded) {
      history.push('/');
    }
  };

  const handleDeleteExpense = (id) => {
    setExpenses(
      expenses.filter((expense) => {
        return expense.id !== id;
      })
    );
  };

  const showSnackbarMessage = (message) => {
    setSnackbarMessage(message);
    setIsSnackbarOpen(true);
  };

  const handleSnackbarClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackbarOpen(false);
  };

  const dropdownIcon = (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#F7C28E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 9l6 6 6-6"
      ></path>
    </svg>
  );

  React.useEffect(() => {
    async function loadExpenses() {
      try {
        const response = await axios.get('/api/expenses');
        setExpenses(response.data.expenses);
        setAreExpensesLoaded(true);
      } catch (e) {
        console.log(e);
        showSnackbarMessage('Error loading expenses');
      }
    }

    loadExpenses();
  }, []);

  return (
    <React.Fragment>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
      {areExpensesLoaded ? (
        <Box px={3} sx={{ paddingTop: '60px' }}>
          <AppBar position="fixed" className="appbar">
            <Toolbar>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <aside>
                  <IconButton onClick={handleClickBack}>
                    <ChevronLeftIcon height="14" width="14" />
                  </IconButton>
                </aside>
                <section sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" component="div">
                    Expense history
                  </Typography>
                </section>
                <aside></aside>
              </Box>
            </Toolbar>
          </AppBar>

          {/* <Box mb={1}>
            <Typography variant="h2" component="h2">
              Filter expense by
            </Typography>
          </Box> */}
          <Box
            sx={{
              marginTop: '8px',
              paddingBottom: '30px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Grid container spacing={2}>
              <Grid item lg={7} md={7} sm={7} xs={7}>
                <Select
                  labelId="expense-history-months"
                  id="expense-history-months"
                  value={month}
                  label="Month"
                  placeholder="Month"
                  onChange={handleChangeMonth}
                  fullWidth
                  size="small"
                  IconComponent={dropdownIcon}
                  sx={{
                    backgroundColor: '#3d3f46',
                    '& .MuiSelect-icon': {
                      top: 14,
                    },
                    // "&:hover": {
                    //   "&& fieldset": {
                    //     border: "3px solid red"
                    //   }
                    // }
                  }}
                  // This actually gives the focus when you hover
                >
                  <MenuItem value="January">January</MenuItem>
                  <MenuItem value="February">February</MenuItem>
                  <MenuItem value="March">March</MenuItem>
                  <MenuItem value="April">April</MenuItem>
                  <MenuItem value="May">May</MenuItem>
                  <MenuItem value="June">June</MenuItem>
                  <MenuItem value="July">July</MenuItem>
                  <MenuItem value="August">August</MenuItem>
                  <MenuItem value="September">September</MenuItem>
                  <MenuItem value="October">October</MenuItem>
                  <MenuItem value="November">November</MenuItem>
                  <MenuItem value="December">December</MenuItem>
                </Select>
              </Grid>
              <Grid item lg={5} md={5} sm={5} xs={5}>
                {/* <Box mb={1}>
                  <Typography variant="h2" component="h2">Year</Typography>
                </Box> */}
                <Select
                  labelId="expense-history-years"
                  id="expense-history-years"
                  value={year}
                  label="Year"
                  placeholder="Year"
                  onChange={handleChangeYear}
                  fullWidth
                  size="small"
                  IconComponent={dropdownIcon}
                  MenuProps={{
                    sx: {
                      maxHeight: 300,
                    },
                  }}
                  sx={{
                    backgroundColor: '#3d3f46',
                    // "&:hover": {
                    //   "&& fieldset": {
                    //     border: "3px solid red"
                    //   }
                    // }
                    '& .MuiSelect-icon': {
                      top: 14,
                    },
                  }}
                >
                  <MenuItem value="2022">2022</MenuItem>
                  <MenuItem value="2021">2021</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <ExpenseTable expenses={expenses} onDeleteExpense={handleDeleteExpense} />
            <ScrollTop />
          </Box>
        </Box>
      ) : (
        <Spinner />
      )}
      <BottomNav />
    </React.Fragment>
  );
}
