import { Box, CircularProgress } from '@mui/material';

export default function Spinner() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        margin: 'auto',
        position: 'fixed',
        top: '-10%',
        left: 0,
        bottom: 0,
        right: 0,
        background: 'rgba(25,28,36,.6)',
        zIndex: 'modal',
      }}
    >
      <CircularProgress size={50} />
    </Box>
  );
}
