import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Theme from './Theme';
import '../css/global.css';

import About from './pages/About';
import ErrorBoundary from './ErrorBoundary';
import CreateExpense from './pages/CreateExpense';
import ExpenseHistory from './pages/ExpenseHistory';
import ViewExpense from './pages/ViewExpense';
import Home from './pages/Home';
// import BottomNav from './components/ui/BottomNav';
import Login from './pages/Login';
import { ProvideAuth, PrivateRoute } from './use-auth.js';

export default function SugarbillsApp() {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={Theme}>
        <ProvideAuth>
          <Router>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <PrivateRoute path="/about">
                <About />
              </PrivateRoute>
              <PrivateRoute path="/create-expense">
                <CreateExpense />
              </PrivateRoute>
              <PrivateRoute path="/expense-history">
                <ExpenseHistory />
              </PrivateRoute>
              <PrivateRoute path="/view-expense">
                <ViewExpense />
              </PrivateRoute>
              <PrivateRoute exact path="/">
                <Home />
              </PrivateRoute>
            </Switch>
            {/* <BottomNav /> */}
          </Router>
        </ProvideAuth>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

if (document.getElementById('sugarbills-app')) {
  ReactDOM.render(<SugarbillsApp />, document.getElementById('sugarbills-app'));
}
