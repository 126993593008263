import * as React from 'react';
import { Box } from '@mui/material';
import MiscInput from './MiscInput';

export default function MiscInputList(props) {
  const { users, onChange, enableTax, enableTip, enableFees } = props;

  const taxId = 1000;
  const tipId = 1001;
  const firstFeeId = 2000;

  const [tax, setTax] = React.useState({ id: taxId });
  const [tip, setTip] = React.useState({ id: tipId });

  const [fees, setFees] = React.useState([{ id: firstFeeId }]);
  const [nextFeeId, setNextFeeId] = React.useState(firstFeeId + 1);

  const handleChangeTax = (_updatedTaxId, updatedTax) => {
    setTax(updatedTax);
  };

  const handleChangeTip = (_updatedTipId, updatedTip) => {
    setTip(updatedTip);
  };

  const handleChangeFee = (updatedFeeId, updatedFee) => {
    setFees(
      fees.map((fee) => {
        if (fee.id == updatedFeeId) {
          return { ...updatedFee, id: fee.id };
        }

        return fee;
      })
    );
  };

  const handleAddFee = () => {
    setFees([...fees, { id: nextFeeId, name: 'Fee' }]);
    setNextFeeId(nextFeeId + 1);
  };

  const handleRemoveFee = (feeId) => {
    setFees(fees.filter((fee) => fee.id != feeId));
  };

  React.useEffect(() => {
    let purchases = [];

    if (enableTax) {
      purchases.push(tax);
    }

    if (enableTip) {
      purchases.push(tip);
    }

    if (enableFees) {
      purchases.push(...fees);
    }

    onChange(purchases);
  }, [enableTax, tax, enableTip, tip, enableFees, fees]);

  React.useEffect(() => {
    if (!enableTax) {
      setTax({ id: taxId });
    }

    if (!enableTip) {
      setTip({ id: tipId });
    }

    if (!enableFees) {
      setFees([{ id: firstFeeId }]);
    }
  }, [enableTax, enableTip, enableFees]);

  return (
    <Box>
      {enableTax && (
        <MiscInput
          name="Tax"
          purchaseId={tax.id}
          users={users}
          onChange={handleChangeTax}
        />
      )}
      {enableTip && (
        <MiscInput
          name="Tip"
          purchaseId={tip.id}
          users={users}
          onChange={handleChangeTip}
        />
      )}
      {enableFees &&
        fees.map((fee) => (
          <MiscInput
            name="Fee"
            purchaseId={fee.id}
            users={users}
            onAdd={handleAddFee}
            onRemove={handleRemoveFee}
            onChange={handleChangeFee}
            enableAdd={fee.id == fees.slice(-1)[0].id}
            addButtonText={'Add fee'}
            enableRemove={fees.length > 1}
            key={fee.id}
          />
        ))}
    </Box>
  );
}
