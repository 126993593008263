import * as React from 'react';
import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { ChevronDownIcon } from '../../components/ui/icons';

const filter = createFilterOptions();

export default function ExpenseCategoryAutocomplete(props) {
  const { options, value, onChange, onSubmit } = props;

  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      name: '',
      discount: '',
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: '',
    discount: 0,
  });

  async function handleSubmit(event) {
    event.preventDefault();

    const newValue = await onSubmit(dialogValue.name, Number(dialogValue.discount));
    if (newValue) {
      onChange(newValue);
    } else {
      throw 'Invalid expense category returned from onSubmit()';
    }

    handleClose();
  }

  return (
    <React.Fragment>
      <Box mb={1}>
        <Typography variant="h2" component="h2">
          Rewards category
        </Typography>
      </Box>
      <Autocomplete
        value={value}
        onChange={(_event, newValue) => {
          if (typeof newValue === 'string') {
            // i.e., the user hit enter. timeout to avoid instant validation of the
            // dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue,
                discount: 0,
              });
            });
          } else if (newValue && newValue.inputValue) {
            // i.e., the user clicked "Add"
            toggleOpen(true);
            setDialogValue({
              name: newValue.inputValue,
              discount: 0,
            });
          } else {
            // i.e., the user selected an existing option
            onChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (
            params.inputValue !== '' &&
            !filtered.some((option) => {
              return option.name == params.inputValue;
            })
          ) {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        options={options}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return `${option.name} - ${option.discount * 100}%`;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        blurOnSelect
        forcePopupIcon
        popupIcon={<ChevronDownIcon height="24" width="24" />}
        placeholder="Select or create a new expense category"
        renderOption={(props, option) => {
          if ('discount' in option) {
            return (
              <li {...props} key={option.id ?? 0}>
                {`${option.name} - ${option.discount * 100}%`}
              </li>
            );
          }

          return (
            <li {...props} key={option.id ?? 0}>
              {`${option.name}`}
            </li>
          );
        }}
        fullWidth
        freeSolo
        renderInput={(params) => <TextField {...params} label="Rewards category" />}
        sx={{
          '& .MuiOutlinedInput-root .MuiAutocomplete-input':{
            padding: '4px 4px 6px 6px',
          },
          '& .MuiAutocomplete-clearIndicator > .MuiSvgIcon-root': {
            fontSize: '1rem',
          },
          '& .MuiAutocomplete-popupIndicator': {
            margin: '1px 2px 0 !important',
          },
          '& .MuiAutocomplete-popupIndicator > .MuiSvgIcon-root': {
            fontSize: '2rem',
          },
        }}
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new rewards category</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value,
                })
              }
              label="name"
              type="text"
              variant="standard"
            />
            <TextField
              margin="dense"
              id="discount"
              value={dialogValue.discount}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  discount: event.target.value,
                })
              }
              label="discount"
              type="number"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
