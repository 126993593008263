import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

const authContext = React.createContext();

// Provider component that wraps your app and makes auth object available to any child
// component that calls useAuth()
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => {
  return React.useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  async function login(email, password) {
    const credentials = { email: email, password: password, remember: true };

    try {
      const response = await axios.post('/api/login', credentials);
    } catch (e) {
      console.log(e);
      return null;
    }

    try {
      const response = await axios.get('/api/users/me');
      setUser(response.data.user);
      return response.data.user;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async function logout() {
    try {
      const response = await axios.post('/api/logout');
      setUser(null);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  React.useEffect(() => {
    async function loadUser() {
      try {
        const response = await axios.get('/api/users/me');
        setUser(response.data.user);
      } catch (e) {
        // do nothing
      } finally {
        setIsLoading(false);
      }
    }

    loadUser();
  }, []);

  // see https://usehooks.com/useAuth/ for other auth methods

  // Return the user object and auth methods
  return { isLoading, user, login, logout };
}

export function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.isLoading ? (
          <React.Fragment />
        ) : auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
