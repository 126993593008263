import * as React from 'react';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SelectedIcon } from './icons';

export default function ButtonSelector(props) {
  const { title, options, selectedId, onChange } = props;

  if (options == null) {
    return <p>Error: options is null</p>;
  }

  const handleClick = (event) => {
    onChange(event.target.value);
  };

  const items = options.map((option) => {
    return (
      <Button
        sx={{
          fontSize: 14,
          marginRight: 1,
          padding: '5px 14px 6px 16px',
          '& .MuiButton-startIcon': {
            marginRight: '4px',
          },
        }}
        key={option.id}
        value={option.id}
        variant="outlined"
        color={option.id == selectedId ? 'primary' : 'secondary'}
        onClick={handleClick}
        startIcon={
          option.id == selectedId ? <SelectedIcon width="14" height="13" /> : null
        }
      >
        {option.name}
      </Button>
    );
  });

  return (
    <React.Fragment>
      {title && (
        <Box mt={1.5} mb={1}>
          <Typography variant="h2" component="h2">
            Sugar daddy, who dat?
          </Typography>
        </Box>
      )}
      {items}
    </React.Fragment>
  );
}
