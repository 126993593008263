import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#F7C28E',
      dark: '#F7C28E',
      light: '#F7C28E',
    },
    secondary: {
      main: '#C4C4C4',
    },
    neutral: {
      main: 'red',
    },
    grey: {
      50: '#525252',
      100: '#c4c4c4',
    },
  },
  typography: {
    fontFamily: 'Nunito, Roboto, sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 600,
    h2: {
      fontSize: 16,
      color: '#C4C4C4',
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: '#F7C28E',
          },
        },
        input: {
          padding: '2px 4px 4px 6px !important',
        },
        listbox: {
          padding: 0,
          '.MuiAutocomplete-option[aria-selected="true"]': {
              borderTop: '.5px solid #f7c28e',
              borderBottom: '.5px solid #f7c28e',
              backgroundColor: 'rgba(0,0,0,.1) !important',
              color: '#F7C28E',
          },
          '.MuiAutocomplete-option:hover': {
            backgroundColor: 'rgba(0,0,0,.1)',
          },
        },
        option: {
          paddingTop: '2px !important',
          "&.Mui-focused": { 
            backgroundColor: 'rgba(0,0,0,.1) !important',
            // This is when you randomly hover over something and leave your mouse off it, it stupidly has a different color
          },
        },
        paper: {
          backgroundColor: '#25282f',
          marginTop: 1,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          textTransform: 'initial',
          background:
            'linear-gradient(89.31deg, #F6C18D 33.17%, #CD9A68 99.08%)',
          borderRadius: 30,
          padding: '4px 24px 6px',
          border: '1px solid #F7C28E',
          fontSize: 18,
          fontWeight: 600,
          color: '#000',
        },
        outlined: {
          textTransform: 'initial',
          borderRadius: 30,
        },
      },
    },
    MuiFab:{
      styleOverrides: {
        root: {
          "&.Mui-disabled": { 
            backgroundColor: 'rgba(0,0,0,1)',
          },
        },
      },
    },
    MuiMenu:{
      styleOverrides: {
        list: { backgroundColor: '#3d3f46'},
        paper: {
            maxHeight: '300px !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          backgroundColor: 'rgba(37,40,47,.6)',
          color: '#C4C4C4',
          fontSize: '18px',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(247, 194, 142, .8)',
          },
          "&.Mui-focused fieldset":{
            borderColor: 'rgba(247, 194, 142, .8) !important',
            borderWidth: '1.5px !important',
          },

        },
        input: {
          padding: '12px 14px 12px',
        },
        notchedOutline: {
          borderColor: 'rgba(37,40,47,.6)',
          top: 0,
          legend: {
            display: 'none',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          // '&:active': {
          //   backgroundColor: 'purple',
          // },
          '&:hover': {
            backgroundColor: 'gray',
            // in a menu item, if you hover over any other items that are not selected this is the bgcolor
          },
          '&.Mui-selected.Mui-focusVisible': {
            backgroundColor: '#F7C28E', 
            color: 'black',
            // if a menu item is already selected, this is the selected bgcolor 
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#F7C28E',
            color: 'black',
            // if a menu item is already selected, and you hover over that selected item, that is that bgcolor
          },
          '&.Mui-selected': {
            backgroundColor: '#F7C28E',
            color: 'black',
            // if a menu item is already selected, but you randomly cancel out of it, this is the bgcolor
          },
        },
      },
    },
  },
});

export default theme;
